@import './colors.scss';
@import './bootstrap.scss';
@import './input-range.scss';
@import '~input-range-scss/_inputrange.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600&display=swap');

@import '../react-piano/styles.scss';

@mixin fonts {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

// https://github.com/steinbergmedia/bravura/blob/master/LICENSE.txt
@font-face {
    font-family: bravura-text;
    src: url(../assets/BravuraTextSubset.woff);
}

:root {
    @media (max-width: 767px) {
        body {
            font-size: 16px;
        }
    }
}

body {
    background-color: $color-background;
    @include fonts;
}

input {
    @include fonts;
}

a {
    text-decoration: none;
    font-weight: 600;
    color: blue;
}

h3,
h5 {
    margin: 0.2em 0 1em;
}

h5 {
    font-size: 1.2rem;
    font-weight: 500;
}

$color-mw-note-label-shadow: rgba(0, 0, 0, 0.8);

.midi-waterfall-note-label {
    text-anchor: middle;
    font-family: 'Open Sans';
    font-size: 1.2rem;
    text-shadow: 0px 0px 3px $color-mw-note-label-shadow;
}

.midi-waterfall-note-label-accidental {
    font-family: bravura-text;
}

.ReactModalPortal {
    z-index: 1000;
    position: relative;
}

.react-modal-overlay {
    background-color: hsla(0deg, 0%, 0%, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}