// SCSS styles
// NB. during the build process this is copied as-is into the dist folder.
// The client app must compile the scss...

// Classes could be rationalised
// e.g. could infer ReactPiano__NoteLabel--accidental ReactPiano__NoteLabel--natural from NoteLabel
// class and 'child-of' selectors of natural/accidental keys.


$react-piano-color-active: hsl(130, 68%, 71%) !default;
$react-piano-color-struck: hsl(130, 68%, 41%) !default;
$react-piano-color-hint: scale-color($react-piano-color-active, $lightness: -17%, $saturation: 30%) !default;
$react-piano-color-active-lh: hsl(211, 80%, 69%) !default;
$react-piano-color-struck-lh: hsl(211, 80%, 39%) !default;
$react-piano-color-hint-lh: scale-color($react-piano-color-active-lh, $lightness: -17%, $saturation: 30%) !default;
$react-piano-color-active-lowlight: hsl(211, 0%, 61%) !default;
// $react-piano-color-struck-lowlight: hsl(211, 0%, 37%) !default;

.ReactPiano__Container {
  background-color: #ddd;
}

.ReactPiano__Keyboard {
  /* Used for absolute positioning of .ReactPiano__Key--accidental elements */
  position: relative;
  /* Used to lay out .ReactPiano__Key--natural elements */
  display: flex;
}

.ReactPiano__Key {
  /* Used for flexbox layout of the child .ReactPiano__NoteLabelContainer elements */
  display: flex;
  position: absolute;
  box-sizing: border-box;
}

/*
 * Styles of accidental notes (flat or sharp)
 */
.ReactPiano__Key--accidental {
  background: #333; // was #666 - made darker so the hint circles show up better
  border: 1px solid #fff;
  border-top: 1px solid transparent;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  height: 66%;
  /* Overlay on top of natural keys */
  z-index: 1;
  /* Use absolute positioning along with inline styles specified in JS to put keys in correct locations. */
  position: absolute;
  top: 0;
}

/*
 * Styles of natural notes (white keys)
 */
.ReactPiano__Key--natural {
  background: #f6f5f3;
  border: 1px solid #888;
  border-radius: 0 0 6px 6px;
  cursor: pointer;
  height: 100%;
  z-index: 0;
  /*
   * Uses flexbox with margin instead of absolute positioning to have more consistent margin rendering.
   * This causes inline styles to be ignored.
   */
  flex: 1;
  margin-right: 0px;
}

.ReactPiano__Key--natural:last-child {
  /* Don't render extra margin on the last natural note */
  margin-right: 0;
}

/*
 * Styles of "active" or pressed-down keys
 */

@mixin key-struck-effect($col) {
  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $col;
    mix-blend-mode: multiply;
    animation: pulse 3s forwards ease-out;
  }
}

@mixin key-struck-effect-accidental($col) {
  @include key-struck-effect($col);

  &::before {
    mix-blend-mode: screen;
  }
}

.ReactPiano__Key--active.ReactPiano__Key--natural {
  background: $react-piano-color-active;
  border: 1px solid darken($react-piano-color-active, 10%);
  border-top: 1px solid #888;
  /* Slight height reduction for "pushed-down" effect */
  height: 98%;
  @include key-struck-effect($react-piano-color-struck)
}

.ReactPiano__Key--active.ReactPiano__Key--accidental {
  background: darken($react-piano-color-active, 30%);
  border: 1px solid darken($react-piano-color-active, 5%);
  border-top: 1px solid #888;
  height: 65%;
  @include key-struck-effect-accidental(darken($react-piano-color-struck, 20%));
}

.ReactPiano__Key--activeLH.ReactPiano__Key--natural {
  background: $react-piano-color-active-lh;
  border: 1px solid darken($react-piano-color-active-lh, 10%);
  border-top: 1px solid #888;
  height: 98%;
  @include key-struck-effect($react-piano-color-struck-lh)
}

.ReactPiano__Key--activeLH.ReactPiano__Key--accidental {
  background: darken($react-piano-color-active-lh, 30%);
  border: 1px solid darken($react-piano-color-active-lh, 5%);
  border-top: 1px solid #888;
  height: 65%;
  @include key-struck-effect-accidental(darken($react-piano-color-struck-lh, 20%));
}

.ReactPiano__Key--activeLowlight.ReactPiano__Key--natural {
  background: $react-piano-color-active-lowlight;
  border: 1px solid darken($react-piano-color-active-lowlight, 10%);
  border-top: 1px solid #888;
  height: 98%;
  @include key-struck-effect($react-piano-color-struck-lowlight)
}

.ReactPiano__Key--activeLowlight.ReactPiano__Key--accidental {
  background: darken($react-piano-color-active-lowlight, 43%);
  border: 1px solid darken($react-piano-color-active-lowlight, 5%);
  border-top: 1px solid #888;
  height: 65%;
  @include key-struck-effect-accidental(darken($react-piano-color-struck-lowlight, 26%));
}


/*
 * Styles for disabled state
 */
.ReactPiano__Key--disabled.ReactPiano__Key--accidental {
  background: #ddd;
  border: 1px solid #999;
}

.ReactPiano__Key--disabled.ReactPiano__Key--natural {
  background: #eee;
  border: 1px solid #aaa;
}

/*
 * Styles for the note label inside a piano key
 */
.ReactPiano__NoteLabelContainer {
  flex: 1;
  /* Align children .ReactPiano__NoteLabel to the bottom of the key */
  align-self: flex-end;
  z-index: 2;
}

.ReactPiano__NoteLabel {
  font-size: 12px;
  text-align: center;
  /* Disable text selection */
  user-select: none;
}

.ReactPiano__NoteLabel--accidental {
  color: hsl(33, 19%, 71%); // was #f8e8d5;
  margin-bottom: 3px;
}

.ReactPiano__NoteLabel--natural {
  color: #888;
  margin-bottom: 3px;
}

.ReactPiano__Key--active .ReactPiano__NoteLabel,
.ReactPiano__Key--activeLH .ReactPiano__NoteLabel,
.ReactPiano__Key--activeLowlight .ReactPiano__NoteLabel {
  color: #fff;
}

/*
 * Styles for the note 'hints'
 */
.ReactPiano__KeyHintContainer {
  // flex: 1;
  /* Align children to the bottom of the key */
  // align-self: flex-end;
  position: absolute;
  bottom: 15px;
  width: 100%;
  font-size: 20px;
  text-align: center;
  z-index: 3;
  color: $react-piano-color-hint;

  &.LeftHand {
    color: $react-piano-color-hint-lh;
  }
}

.ReactPiano__Key--accidental .ReactPiano__KeyHintContainer {
  bottom: 32px;
  // filter: drop-shadow(0px 0px 2px black);
}


@keyframes pulse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}