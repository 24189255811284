$color-background: hsl(184, 41%, 75%);
$color-secondary: hsl(61, 66%, 84%);
$color-success: hsl(100, 66%, 84%);

$right: hsl(2.35, 62.2%, 67.84%); // "#e07e7a" 
$left: hsl(288.42, 36.31%, 69.22%); // "#C294CD"
$lowlight: hsl(288.42, 0%, 70%);
$disabled: hsla(0, 0%, 90%, 0.15); // "#C294CD"

$react-piano-color-active: scale-color($right, $lightness: 17%);
$react-piano-color-struck: $right;
$react-piano-color-hint: scale-color($react-piano-color-active, $lightness: -27%, $saturation: 30%) !default;

$react-piano-color-active-lh: scale-color($left, $lightness: 17%);
$react-piano-color-struck-lh: $left;
$react-piano-color-hint-lh: scale-color($react-piano-color-active-lh, $lightness: -27%, $saturation: 30%) !default;

$react-piano-color-active-lowlight: scale-color($lowlight, $lightness: 12%);
$react-piano-color-struck-lowlight: $lowlight;


$midi-waterfall-color-right: scale-color($right, $alpha: -30%);
$midi-waterfall-color-black-note-right: scale-color($right, $lightness: -30%, $alpha: -30%);
$midi-waterfall-color-left: scale-color($left, $alpha: -30%);
$midi-waterfall-color-black-note-left: scale-color($left, $lightness: -30%, $alpha: -30%);
$midi-waterfall-color-disabled: $disabled;
$midi-waterfall-color-black-note-disabled: scale-color($disabled, $lightness: -30%);
$midi-waterfall-color-label: hsl(288.42, 95%, 99%);


:export {
    midiWaterfallColorRight: $midi-waterfall-color-right;
    midiWaterfallColorBlackNoteRight: $midi-waterfall-color-black-note-right;
    midiWaterfallColorLeft: $midi-waterfall-color-left;
    midiWaterfallColorBlackNoteLeft: $midi-waterfall-color-black-note-left;
    midiWaterfallColorDisabled: $midi-waterfall-color-disabled;
    midiWaterfallColorBlackNoteDisabled: $midi-waterfall-color-black-note-disabled;
    midiWaterfallColorLabel: $midi-waterfall-color-label;
}