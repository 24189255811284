// These variables are all used by _inputrange.scss which is imported from input-range-scss


$track-color: hsla(0deg, 0%, 20%, 0.4);
$thumb-color: hsla(0deg, 0%, 20%, 0.7);

$thumb-radius: 0;
$thumb-height: 18px;
$thumb-width: 6px;
$thumb-shadow-size: 1px;
// $thumb-shadow-blur: 4px;
// $thumb-shadow-color: rgba(0, 0, 0, .2);
$thumb-border-width: 0;
// $thumb-border-color: #eceff1;

// $track-width: 34%;
$track-height: 4px;
$track-shadow-size: 0;
// $track-shadow-blur: 1px;
// $track-shadow-color: rgba(0, 0, 0, .2);
$track-border-width: 0;
$track-border-color: #cfd8dc;

$track-radius: 0;
// $contrast: 5%;

// $ie-bottom-track-color: darken($track-color, $contrast);

input[type=range] {
    vertical-align: middle;
}